import React from 'react';
import { Link } from 'react-router-dom';
import { formatearFecha } from '../../../utils/date';
import { roles } from '../../../constants';
import { useAppSelector } from '../../../redux/hooks';
import { usersSelector } from '../../../redux/users';
import { expiresInOneWeek, isExpired } from '../../../utils/isExpired';
import { CardChips } from '../../../components/Chips/CardChips';
const PolizaCard = (props) => {
  const user = useAppSelector(usersSelector.userData);

  const fechaCargado = props.poliza.initializedAt
    ? new Date(props.poliza.initializedAt)
    : false;
  const fechaCargadaFormateada = fechaCargado
    ? formatearFecha(fechaCargado)
    : '';

  const fechaVencimiento = props.poliza.expiresAt
    ? new Date(props.poliza.expiresAt)
    : false;
  const fechaVencimientoFormateada = fechaVencimiento
    ? formatearFecha(fechaVencimiento)
    : '';

  return (
    <div className="card text-center mb-3 w-90">
      <div className="card-body">
        <CardChips poliza={props.poliza} />
        <h5 className="card-title text-start">
          {props.poliza.insuranceCompany}
        </h5>
        <p className="mt-0 text-start"><b>Pertenece a:</b> <Link to={`/usuarios/${props.poliza.userId._id}`}>{props.poliza.userId.socialReason}</Link></p>
        <p className="card-text text-start">
          <b>Tipo: </b> {props.poliza.polizaType}
          {props.poliza.polizaType === 'Flota' && (
            <>
              {' '}
              con <b> {props.poliza.float.length} vehículos</b>
            </>
          )}
        </p>
        <p className="card-text text-start">
          <b>Compañía: </b>{props.poliza.insuranceCompany}
        </p>
        <p className="card-text text-start">
          <b>Póliza: </b>{props.poliza.polizaNumber}
        </p>
        {props.poliza.domain && props.poliza.domain !== '' && (
          <p className="card-text text-start">
            <b>Dominio: </b>{props.poliza.domain}
          </p>
        )}
        <p className="card-text text-start">
          <b>Endosos: </b>{props.poliza.endosos.length}
        </p>
        {user.role === roles.supreme &&
          (isExpired(props.poliza.expiresAt) || expiresInOneWeek(props.poliza.expiresAt)) &&
          !props.poliza.anulled &&
          !props.poliza.renewed && (
            <Link
              to="/polizas/cargar"
              state={{
                userDetail: props.poliza.userId,
                oldPoliza: props.poliza,
              }}
              className="btn btn-primary"
            >
              Renovar
            </Link>
          )}
        <Link
          to={`/polizas/${props.poliza._id}`}
          className="btn btn-success mt-1"
        >
          Ver póliza
        </Link>
      </div>
      <div className="card-footer text-body-secondary rowCardList row row-cols-sm-1 row-cols-md-1 row-cols-lg-2 ">
        <p className="mb-md-3 mb-lg-0 text-lg-start text-md-center">
          Alta de póliza el {fechaCargadaFormateada}
        </p>
        <p className="mb-0 text-lg-end text-md-center">
          {new Date() >= new Date(props.poliza.expiresAt)
            ? 'Vencida el'
            : 'Vence el'}{' '}
          {fechaVencimientoFormateada}
        </p>
      </div>
    </div>
  );
};

export default PolizaCard;
