import React, { useEffect, useState } from 'react'
import { formatDateToDDMMYYYY } from '../../../utils/date';
import { useAppDispatch } from '../../../redux/hooks';
import Spinner from '../../../components/Spinner/Spinner';
import { isExpired } from '../../../utils/isExpired';
import '../Stats.css';
import { insuranceTypes, rolenames } from '../../../constants';
import { getUsersStats } from '../../../redux/stats';
import { Link } from 'react-router-dom';

export const StatsUsers = () => {
  const dispatch = useAppDispatch();

  const [backupUsersData, setBackupUsersData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [filtering, setFiltering] = useState({
    socialReason: 'a-z',
    documentType: 'ALL',
    polizasAmount: 'asc',
    siniestrosAmount: 'asc',
    createdAt: 'newest',
  });

  const handleGetUsers = async () => {
    const res = await dispatch(getUsersStats()).unwrap();
    if (!res) return;

    let users = res;

    users = users.map((user) => {
      const userPolizas = user.polizas.filter(poliza => {
        if (!isExpired(poliza.expiresAt) && !poliza.anulled) return poliza;
      })

      const userPolizasType = insuranceTypes.map(type => {
        const auxUserPolizasType = userPolizas.filter(poliza => poliza.polizaType === type.value);
        return { type: type.value, amount: auxUserPolizasType.length };
      })
      return { ...user, polizas: userPolizasType };
    })
    if (!backupUsersData.length) setBackupUsersData(users)
    setUsersData(users);
  }

  const handleProcessData = (data, filters) => {

    if (filters.socialReason) {
      if (filters.socialReason === 'a-z') {
        data.sort((a, b) => a.socialReason.localeCompare(b.socialReason))
      } else {
        data.sort((a, b) => b.socialReason.localeCompare(a.socialReason))
      }
    }
    if (filters.createdAt) {
      if (filters.createdAt === 'newest') {
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      } else {
        data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      }
    }
    if (filters.polizasAmount) {
      data.sort((a, b) => {
        const totalAmountA = a.polizas.reduce((acc, curr) => acc + curr.amount, 0);
        const totalAmountB = b.polizas.reduce((acc, curr) => acc + curr.amount, 0);

        if (filters.polizasAmount === 'asc') {
          return totalAmountA - totalAmountB;
        } else {
          return totalAmountB - totalAmountA;
        }
      });
    }
    if (filters.documentType) {
      data = backupUsersData;
      if (filters.documentType === 'DNI') {
        data = data.filter(user => user.document.documentType === 'DNI')
      } else if (filters.documentType === 'CUIT') {
        data = data.filter(user => user.document.documentType === 'CUIT')
      } else {
        data = backupUsersData;
      }
    }
    if (filters.siniestrosAmount) {
      if (filters.siniestrosAmount === 'asc') {
        data.sort((a, b) => a.siniestros - b.siniestros)
      } else {
        data.sort((a, b) => b.siniestros - a.siniestros)
      }
    }

    setUsersData([...data]);
  }

  const handleOnChangeFiltering = (e) => {
    setFiltering({ ...filtering, [e.target.name]: e.target.value });
    handleProcessData(usersData, { [e.target.name]: e.target.value });
  }

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (
    <div style={{ width: "95%" }}>
      {!usersData.length
        ? <Spinner text='Preparando los datos...' />
        : <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <select
                  onChange={handleOnChangeFiltering}
                  name="socialReason"
                  className="form-select"
                >
                  <option value='a-z'>A-Z</option>
                  <option value="z-a">Z-A</option>
                </select>
              </th>
              <th scope="col">
                <select
                  onChange={handleOnChangeFiltering}
                  name="documentType"
                  className="form-select"
                  style={{ width: 'max-content' }}
                >
                  <option value='ALL'>Todos</option>
                  <option value="DNI">DNI</option>
                  <option value="CUIT">CUIT</option>
                </select>
              </th>
              <th scope="col">
                <select
                  onChange={handleOnChangeFiltering}
                  name="polizasAmount"
                  className="form-select"
                  style={{ width: 'max-content' }}
                >
                  <option value='desc'>Mayor a menor</option>
                  <option value="asc">Menor a mayor</option>
                </select>
              </th>
              <th scope="col">
                <select
                  onChange={handleOnChangeFiltering}
                  name="siniestrosAmount"
                  className="form-select"
                  style={{ width: 'max-content' }}
                >
                  <option value='desc'>Mayor a menor</option>
                  <option value="asc">Menor a mayor</option>
                </select>
              </th>
              <th scope="col"><select
                onChange={handleOnChangeFiltering}
                name="createdAt"
                className="form-select"
                style={{ width: 'max-content' }}
              >
                <option value='oldest'>Más antiguo</option>
                <option value="newest">Más nuevo</option>
              </select></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col">Razón social</th>
              <th scope="col">Documento</th>
              <th scope="col">Pólizas (Vigentes y activas)</th>
              <th scope="col">Siniestros</th>
              <th scope="col">Fecha registro</th>
            </tr>
            {usersData.map((user) => (
              <tr key={user._id}>
                <td scope='row'>
                  <b className='mb-1'>{rolenames[user.role]}</b>
                  <p className='mb-0'><Link to={`/usuarios/${user._id}`} target='_blank'>{user.socialReason}</Link></p>
                </td>
                <td>{user.document.documentType} - {user.document.documentNumber}</td>
                <td>
                  {user.polizas.map(poliza => {
                    if (poliza.amount > 0) {
                      return (
                        <p key={poliza._id} className="mb-0">
                          <b>{poliza.type}:</b> {poliza.amount}
                        </p>
                      )
                    }
                  })}
                </td>
                <td>{user.siniestros}</td>
                <td>{formatDateToDDMMYYYY(user.createdAt)}</td>
              </tr>
            ))
            }
          </tbody>
        </table>
      }
    </div>
  )
}