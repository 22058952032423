import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import PolizaCard from './PolizaCard/PolizaCard';
import Spinner from '../../components/Spinner/Spinner';
import { useSearchParams, useLocation, Link } from 'react-router-dom';
import Paginator from '../../components/Paginator/Paginator';
import { pagination, roles } from '../../constants';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { usersSelector } from '../../redux/users';
import { downloadPolizasReport, getPolizas, polizasSelectors } from '../../redux/polizas';
import { essentialsSelectors } from '../../redux/essentials';
import './Polizas.scss';

const { DEFAULT_PAGE, DEFAULT_LIMIT } = pagination;

const Polizas = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);
  const polizas = useAppSelector(polizasSelectors.polizasData);
  const loadingPolizas = useAppSelector(polizasSelectors.isLoading);
  const insuranceCompanies = useAppSelector(essentialsSelectors.insuranceCompanies);
  const insuranceTypes = useAppSelector(essentialsSelectors.insuranceTypes);

  const [_, setParams] = useSearchParams();
  const location = useLocation();
  const [onlyAnulled, setOnlyAnulled] = useState(false);
  const [onlyExpired, setOnlyExpired] = useState(false);
  const [search, setSearch] = useState({
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    polizaNumber: '',
    domain: '',
    polizaType: '',
  });

  const handleGetPolizas = async (query) => {
    dispatch(getPolizas(query));
  };

  const handleOnReset = () => {
    setSearch({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
      polizaNumber: '',
      domain: '',
      polizaType: '',
    });
    setParams({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
    });
    handleGetPolizas(`?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`);
  };

  const handleDownloadReport = async ({ from, to }) => {
    dispatch(downloadPolizasReport({ from, to }));
  };

  useEffect(() => {
    const queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    if (!queryObject.page || !queryObject.limit) {
      setParams({
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      });
    } else {
      setParams({
        ...queryObject,
        page: Number(queryObject.page),
      });
      setSearch({
        ...search,
        page: Number(queryObject.page),
      });
    }
    handleGetPolizas(queryString);
  }, [location.search]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setParams(search);
    const queryStringSearch = queryString.stringify(search);
    handleGetPolizas('?' + queryStringSearch);
  };

  const handleOnChange = (e) => {
    if (e.target.name === 'anulled') {
      setOnlyAnulled((prev) => !prev);
      return setSearch({
        ...search,
        anulled: !onlyAnulled,
      });
    }
    if (e.target.name === 'expired') {
      setOnlyExpired((prev) => !prev);
      return setSearch({
        ...search,
        expired: !onlyExpired,
      });
    }
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };

  const onPrevPage = (page) => {
    setSearch({ ...search, page: page || (search.page -= 1) });
    setParams({ ...search, page: page || search.page });
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handleGetPolizas(queryString);
  };

  const onNextPage = async (page) => {
    setSearch({ ...search, page: page || (search.page += 1) });
    setParams({ ...search, page: page || search.page });
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handleGetPolizas(queryString);
  };

  const showReportModal = () => {
    withReactContent(Swal).fire({
      title: 'Reporte de pólizas a vencer',
      html: (
        <div style={{ overflow: 'hidden', height: '100%', paddingTop: '10px' }}>
          <label>
            Desde <span className="requiredFormField">*</span>
          </label>
          <input
            style={{ maxWidth: '250px', margin: 'auto' }}
            className="form-control"
            type="date"
            name="from"
            id="report.from"
          />
          <label>
            Hasta <span className="requiredFormField">*</span>
          </label>
          <input
            style={{ maxWidth: '250px', margin: 'auto' }}
            className="form-control"
            type="date"
            name="to"
            id="report.to"
          />
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Descargar',
      confirmButtonColor: 'green',
      preConfirm: () =>
        handleDownloadReport({
          from: document.getElementById('report.from').value,
          to: document.getElementById('report.to').value,
        }),
    });
  };

  return (
    <>
      <h1>Pólizas</h1>
      <div className="contentContainer card">
        <div className="card-header w-100 text-center">
          <form onSubmit={(e) => handleOnSubmit(e)}>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1 justify-content-center">
              <div className="form-group mb-2">
                <label>Aseguradora</label>
                <select
                  onChange={(e) => handleOnChange(e)}
                  name="insuranceCompany"
                  className="form-select"
                >
                  <option value="">Todas</option>
                  {insuranceCompanies.map((insuranceCompany, index) => (
                    <option
                      key={`${insuranceCompany.value}${index}`}
                      value={insuranceCompany.value}
                    >
                      {insuranceCompany.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Tipo de Póliza </label>
                <select
                  onChange={(e) => handleOnChange(e)}
                  name="polizaType"
                  className="form-select"
                >
                  <option value="">Todas</option>
                  {insuranceTypes.map((insuranceType, index) => (
                    <option key={`${insuranceType.value}${index}`} value={insuranceType.value}>
                      {insuranceType.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Dominio</label>
                <input
                  disabled={search.polizaNumber !== '' ? true : false}
                  className="form-control"
                  onChange={(e) => handleOnChange(e)}
                  name="domain"
                  value={search.domain}
                />
              </div>
              <div className="form-group mb-2">
                <label>Nº de Póliza</label>
                <input
                  disabled={search.domain !== '' ? true : false}
                  className="form-control"
                  onChange={(e) => handleOnChange(e)}
                  name="polizaNumber"
                  value={search.polizaNumber}
                />
              </div>
              <div
                className="form-check form-switch switcherWrapper mb-2"
                style={{ marginRight: '15px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="anulled"
                  role="switch"
                  checked={onlyAnulled}
                  onChange={(e) => handleOnChange(e)}
                  id="flexSwitchCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  Sólo anuladas
                </label>
              </div>
              <div
                className="form-check form-switch switcherWrapper mb-2"
                style={{ marginLeft: '15px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="expired"
                  role="switch"
                  checked={onlyExpired}
                  onChange={(e) => handleOnChange(e)}
                  id="flexSwitchCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  Sólo vencidas
                </label>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center">
              <button type="reset" onClick={handleOnReset} className="btn btn-danger">
                LIMPIAR
              </button>
              <button type="submit" className="btn btn-success">
                FILTRAR
              </button>
            </div>
          </form>
        </div>
        {user.role === roles.supreme && (
          <div className="doubleButtonWrapper">
            <Link to="/polizas/cargar" className="fw-semibold btn btn-success my-2">
              CARGAR PÓLIZA
            </Link>

            <button
              onClick={showReportModal}
              style={{ width: '100%' }}
              className="fw-semibold btn btn-danger my-2"
            >
              REPORTE PÓLIZAS A VENCER
            </button>
          </div>
        )}
        {loadingPolizas ? (
          <Spinner />
        ) : (
          polizas && (
            <>
              <p className="mb-1">Resultados: {polizas.totalDocs}</p>
              {polizas.docs.length > 0 ? (
                polizas.docs.map((poliza) => <PolizaCard key={poliza._id} poliza={poliza} />)
              ) : user.role === roles.client ? (
                <h5 className="mt-4 mb-4">No posees ninguna póliza</h5>
              ) : (
                <h5 className="mt-4 mb-4">No se han encontrado resultados</h5>
              )}
            </>
          )
        )}
        {!loadingPolizas && polizas && (
          <Paginator
            pages={{
              hasPrevPage: polizas.hasPrevPage,
              page: polizas.page,
              hasNextPage: polizas.hasNextPage,
              totalPages: polizas.totalPages,
              onPrevPage: onPrevPage,
              onNextPage: onNextPage,
            }}
          />
        )}
      </div>
    </>
  );
};

export default Polizas;
