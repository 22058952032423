import React, { useState } from 'react'
import { isExpired } from '../../../utils/isExpired';
import Spinner from '../../../components/Spinner/Spinner';
import { essentialsSelectors } from '../../../redux/essentials';
import { useAppSelector } from '../../../redux/hooks';

export const StatsPolizas = ({ polizas }) => {

  const insuranceCompanies = useAppSelector(essentialsSelectors.insuranceCompanies);
  const insuranceTypes = useAppSelector(essentialsSelectors.insuranceTypes);

  const [filtering, setFiltering] = useState({ insuranceCompany: 'ALL', type: 'ALL' });

  const handleOnChangeFiltering = (e) => {
    setFiltering({ ...filtering, [e.target.name]: e.target.value });
  }

  const oneMonthAgoDate = new Date()
  oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);
  const twoMonthsAgoDate = new Date()
  twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 1);

  return (
    <div style={{ width: "95%" }}>

      {!polizas.length
        ? <Spinner text='Preparando los datos...' />
        :
        <>
          <p className="mb-0 mt-2 text-center">
            Las estadísticas están basadas en pólizas vigentes
          </p>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <select
                    onChange={handleOnChangeFiltering}
                    name="insuranceCompany"
                    className="form-select"
                    style={{ width: 'max-content' }}
                  >
                    <option value='ALL'>Todas</option>
                    {insuranceCompanies.map((company) => (
                      <option value={company.value}>{company.label}</option>
                    ))}
                  </select>
                </th>
                <th scope="col">
                  <select
                    onChange={handleOnChangeFiltering}
                    name="type"
                    className="form-select"
                    style={{ width: 'max-content' }}
                  >
                    <option value='ALL'>Todos</option>
                    {insuranceTypes.map((type) => (
                      <option value={type.value}>{type.label}</option>
                    ))}
                  </select>
                </th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="col">Compañía de seguros</th>
                <th scope="col">Tipo</th>
                <th scope="col"></th>
              </tr>
              {insuranceCompanies.map((company, index) => {

                if (filtering.insuranceCompany !== 'ALL') {
                  if (filtering.insuranceCompany === company.value) {
                    return (
                      <tr key={company.label + index}>
                        <th>{company.label}</th>
                        <td>
                          {insuranceTypes.map((type) => {
                            const amount = polizas.filter((poliza) => poliza.insuranceCompany === company.value && poliza.polizaType === type.value && !isExpired(poliza.expiresAt) && !poliza.anulled).length;
                            if (filtering.type !== 'ALL') {
                              if (filtering.type === type.label && amount)
                                return (
                                  <p key={company.label + type.label} className='mb-0'><b>{type.label}</b>: {amount}</p>
                                )
                            } else if (amount) {
                              return (
                                <p key={company.label + type.label} className='mb-0'><b>{type.label}</b>: {amount}</p>
                              )
                            }
                          })}
                        </td>
                      </tr>)
                  }
                } else
                  return (
                    <tr>
                      <th>{company.label}</th>
                      <td>
                        {insuranceTypes.map((type) => {
                          const amount = polizas.filter((poliza) => poliza.insuranceCompany === company.value && poliza.polizaType === type.value && !isExpired(poliza.expiresAt) && !poliza.anulled).length;
                          if (filtering.type !== 'ALL') {
                            if (filtering.type === type.label && amount)
                              return (
                                <p key={company.label + type.label} className='mb-0'><b>{type.label}</b>: {amount}</p>
                              )
                          } else if (amount) {
                            return (
                              <p key={company.label + type.label} className='mb-0'><b>{type.label}</b>: {amount}</p>
                            )
                          }
                        })}
                      </td>
                    </tr>)
              })}

            </tbody>
          </table>
        </>
      }
    </div>
  )
}