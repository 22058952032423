import axios from 'axios';
import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  errorSideAlert,
  successSideAlert
} from '../alerts';
import {
  saveAs
} from 'file-saver';
import {
  studiesStoreKey
} from '.';


const studiesPath = `${process.env.REACT_APP_BACKEND_URL}/studies`

export const createStudy = createAsyncThunk(
  `${studiesStoreKey}/createStudy`,
  async (study, thunkApi) => {
    try {
      const response = await axios.post(`${studiesPath}/create`, study);

      thunkApi.dispatch(successSideAlert('Solicitud de estudios cargado exitosamente'))
      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const getStudies = createAsyncThunk(
  `${studiesStoreKey}/getStudies`,
  async (query, thunkApi) => {
    try {
      const response = await axios.get(studiesPath + query);

      return response.data;
    } catch (error) {
      return thunkApi.dispatch(errorSideAlert(error.response.data.message));
    }
  }
);

export const downloadStudiesReport = createAsyncThunk(
  `${studiesStoreKey}/downloadStudiesReport`,
  async ({
    from,
    to,
    orderedBy
  }, thunkApi) => {
    try {
      const response = await axios.get(
        `${studiesPath}/report?from=${from}&to=${to}&orderedBy=${orderedBy}`, {
        responseType: 'blob'
      },
      );

      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const filename = contentDisposition.split('filename=')[1];
        saveAs(new Blob([response.data]), filename);
        thunkApi.dispatch(successSideAlert("Reporte descargado"));
      }
      return;
    } catch (error) {
      return thunkApi.dispatch(errorSideAlert(error.response.data.message));
    }
  }
);

export const getStudyDetail = createAsyncThunk(
  `${studiesStoreKey}/getStudyDetail`,
  async (studyId, thunkApi) => {
    try {
      const response = await axios.get(`${studiesPath}/id/${studyId}`);

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const updateStudyStatus = createAsyncThunk(
  `${studiesStoreKey}/updateStudyStatus`,
  async ({
    studyId,
    status,
    value = true
  }, thunkApi) => {
    try {
      const response = await axios.put(`${studiesPath}/id/${studyId}`, {
        [status]: value
      });

      thunkApi.dispatch(successSideAlert('Estado de estudio actualizado'))
      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const updateStudy = createAsyncThunk(
  `${studiesStoreKey}/updateStudy`,
  async ({
    studyId,
    formData
  }, thunkApi) => {
    try {
      const response = await axios.put(`${studiesPath}/id/${studyId}`, formData);

      thunkApi.dispatch(successSideAlert("Estudio editado exitosamente!"));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);