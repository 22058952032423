import React, { useState, useEffect } from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import UserCard from '../../../Admin/UsersList/UserCard';
import styles from '../NewPoliza.module.css';
import { getUserByDocument } from '../../../../redux/users';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { createPoliza } from '../../../../redux/polizas';
import { essentialsSelectors } from '../../../../redux/essentials';

export const NewPolizaForm = ({ existingPoliza, userDataFromRedirection }) => {
  const dispatch = useAppDispatch();

  const insuranceCompanies = useAppSelector(essentialsSelectors.insuranceCompanies);
  const insuranceTypes = useAppSelector(essentialsSelectors.insuranceTypes);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userToAssign, setUserToAssign] = useState(false);
  const [searching, setSearching] = useState(false);
  const [userToSearch, setUserToSearch] = useState({
    documentType: '',
    documentNumber: '',
  });
  const [newPoliza, setNewPoliza] = useState({
    userId: '',
    insuranceCompany: '',
    polizaNumber: '',
    polizaType: '',
    polizaPdf: '',
    endosos: [],
    domain: '',
    float: [],
    initializedAt: '',
    expiresAt: '',
  });

  useEffect(() => {
    if (userDataFromRedirection) {
      setUserToAssign(userDataFromRedirection);
      setNewPoliza((prevNewPoliza) => ({
        ...prevNewPoliza,
        userId: userDataFromRedirection._id,
      }));
      setSearching(true);
      setUserToSearch({
        documentType: userDataFromRedirection.document.documentType,
        documentNumber: userDataFromRedirection.document.documentNumber,
      });
    }
  }, [userDataFromRedirection]);

  useEffect(() => {
    if (existingPoliza) {
      setNewPoliza({
        userId: existingPoliza.userId._id,
        insuranceCompany: existingPoliza.insuranceCompany,
        polizaNumber: '',
        polizaType: existingPoliza.polizaType,
        polizaPdf: '',
        endosos: [],
        domain: existingPoliza.domain,
        float: existingPoliza.float.map((vehicle) => {
          return { domain: vehicle.domain, tarjetaCirculacion: '' };
        }),
        initializedAt: '',
        expiresAt: '',
        oldPolizaId: existingPoliza._id,
      });
      setUserToAssign(existingPoliza.userId);
      setUserToSearch({
        documentType: existingPoliza.userId.document.documentType,
        documentNumber: existingPoliza.userId.document.documentNumber,
      });
      setSearching(true);
    }
  }, [existingPoliza]);

  const handleOnSubmitUser = async (e) => {
    e.preventDefault();
    setUserToAssign(false);
    setSearching(true);
    const res = await dispatch(getUserByDocument(userToSearch)).unwrap();
    if (!res) setSearching(false);
    setUserToAssign(res);
    setNewPoliza((prevNewPoliza) => ({
      ...prevNewPoliza,
      userId: res._id,
    }));
  };

  const handleOnChangeUser = (e) => {
    setSearching(false);
    setUserToSearch({
      ...userToSearch,
      [e.target.name]: e.target.value,
    });
  };
  const handleOnChangePoliza = (e, index) => {
    const { name, value, files } = e.target;

    setNewPoliza((prevNewPoliza) => {
      if (name.startsWith('float')) {
        const updatedFloat = [...prevNewPoliza.float];
        if (!updatedFloat[index]) {
          updatedFloat[index] = { domain: '', tarjetaCirculacion: '' };
        }
        if (name.endsWith('domain')) {
          updatedFloat[index].domain = value;
        } else if (name.endsWith('tarjetaCirculacion') && files.length > 0) {
          updatedFloat[index].tarjetaCirculacion = files[0];
        }
        return { ...prevNewPoliza, domain: '', float: updatedFloat };
      } else {
        return { ...prevNewPoliza, [name]: value };
      }
    });
  };

  const handleAddFloat = () => {
    setNewPoliza((prevNewPoliza) => ({
      ...prevNewPoliza,
      float: [...prevNewPoliza.float, { domain: '', tarjetaCirculacion: '' }],
    }));
  };

  const handleRemoveFloat = (indexToRemove) => {
    setNewPoliza((prevNewPoliza) => {
      const updatedFloat = prevNewPoliza.float.filter(
        (_, index) => index !== indexToRemove,
      );
      return { ...prevNewPoliza, float: updatedFloat };
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    const data = newPoliza;

    formData.append('type', 'polizas');
    formData.append('data', JSON.stringify({ data }));

    const polizaPdfInput = document.querySelector('input[name="polizaPdf"]');
    if (polizaPdfInput) {
      const polizaPdfs = polizaPdfInput.files;
      for (let i = 0; i < polizaPdfs.length; i++) {
        const polizaPdf = polizaPdfs[i];
        formData.append('polizaPdf', polizaPdf);
      }
    }

    const tarjetaCirculacionPdfInput = document.querySelector(
      `input[name="tarjetaCirculacionPdf"]`,
    );
    if (tarjetaCirculacionPdfInput) {
      const tarjetaCirculacionPdfs = tarjetaCirculacionPdfInput.files;
      for (let i = 0; i < tarjetaCirculacionPdfs.length; i++) {
        const tarjetaCirculacionPdf = tarjetaCirculacionPdfs[i];
        formData.append('tarjetaCirculacionPdf', tarjetaCirculacionPdf);
      }
    }

    for (let i = 0; i < newPoliza.float.length; i++) {
      const flotaFilesInput = document.querySelector(
        `input[name="float${i}.tarjetaCirculacion"]`,
      );
      if (flotaFilesInput) {
        const flotaFiles = flotaFilesInput.files;
        if (flotaFiles.length > 0) {
          formData.append('flotaFiles', flotaFiles[0]);
        }
      }
    }

    const flotaFilesInput = document.querySelector('input[name="flotaFiles"]');
    if (flotaFilesInput) {
      const flotaFiles = flotaFilesInput.files;
      for (let i = 0; i < flotaFiles.length; i++) {
        formData.append('flotaFiles', flotaFiles[i]);
      }
    }
    const res = await dispatch(createPoliza(formData)).unwrap();
    setLoading(false);
    if (!res) return;
    navigate(`/polizas/${res._id}`);
  };

  return (
    <>
      <form onSubmit={handleOnSubmitUser} className="searchUserForm">
        <h5 className="card-title text-center mt-2">
          Buscá el cliente que le asignarás la póliza
        </h5>
        <div className={styles.doubleInputWrapper} style={{ marginBottom: "1rem" }}>
          <div className={styles.labelAndInputWrapper}>
            <label>
              Tipo de documento <span className="requiredFormField">*</span>
            </label>
            <select
              className="form-select mb-1"
              onChange={handleOnChangeUser}
              name="documentType"
              defaultValue={userToSearch.documentType === 'DNI'}
            >
              <option>Tipo de documento</option>
              <option
                value="DNI"
              >
                DNI
              </option>
              <option
                selected={userToSearch.documentType === 'CUIT'}
                value="CUIT"
              >
                CUIT
              </option>
            </select>
          </div>
          <div className={styles.labelAndInputWrapper}>
            <label>
              N° de Documento <span className="requiredFormField">*</span>
            </label>
            <input
              className="form-control"
              name="documentNumber"
              placeholder="Ingresá acá el DNI o CUIT"
              onChange={handleOnChangeUser}
              value={userToSearch.documentNumber}
              type="number"
              required
            />
          </div>
        </div>
        {searching ? (
          userToAssign ? (
            <UserCard user={userToAssign} preview />
          ) : (
            <Spinner />
          )
        ) : (
          false
        )}
        <div className="searchUserButtonWrapper">
          <button type="submit" className="btn btn-info mt-1 mb-2">
            Buscar Usuario
          </button>
        </div>
      </form>
      <form onSubmit={handleOnSubmit} className={styles.reportForm}>
        <label>
          Compañia <span className="requiredFormField">*</span>
        </label>
        <select
          name="insuranceCompany"
          onChange={handleOnChangePoliza}
          className="form-select"
          required
        >
          <option value={null}>Seleccione la compañía</option>
          {insuranceCompanies.map((insuranceCompany, index) => {
            let selected = false;
            if (
              existingPoliza &&
              existingPoliza.insuranceCompany === insuranceCompany.value
            )
              selected = true;
            return (
              <option
                selected={selected}
                key={`${insuranceCompany.value}${index}`}
                value={insuranceCompany.value}
              >
                {insuranceCompany.label}
              </option>
            );
          })}
        </select>
        <div className={styles.doubleInputWrapper}>
          <div className={styles.labelAndInputWrapper}>
            <label>
              Tipo de Póliza <span className="requiredFormField">*</span>
            </label>
            <select
              name="polizaType"
              onChange={handleOnChangePoliza}
              className="form-select"
              required
            >
              <option value={null}>Seleccione el tipo de póliza</option>
              {insuranceTypes.map((insuranceType, index) => {
                let selected = false;
                if (
                  existingPoliza &&
                  existingPoliza.polizaType === insuranceType.value
                )
                  selected = true;
                return (
                  <option
                    selected={selected}
                    key={`${insuranceType.value}${index}`}
                    value={insuranceType.value}
                  >
                    {insuranceType.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={styles.labelAndInputWrapper}>
            <label>
              Número de Póliza <span className="requiredFormField">*</span>
            </label>
            <input
              className="form-control"
              name="polizaNumber"
              onChange={handleOnChangePoliza}
              value={newPoliza.polizaNumber}
              type="text"
              required
            />
          </div>
        </div>
        <div className={styles.doubleInputWrapper}>
          <div className={styles.labelAndInputWrapper}>
            <label>
              Fecha de alta de la póliza{' '}
              <span className="requiredFormField">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              id="fechaInicializada"
              name="initializedAt"
              onChange={handleOnChangePoliza}
              required
            />
          </div>
          <div className={styles.labelAndInputWrapper}>
            <label>
              Fecha de expiración <span className="requiredFormField">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              id="fechaExpiración"
              name="expiresAt"
              onChange={handleOnChangePoliza}
              required
            />
          </div>
        </div>
        {(() => {
          switch (newPoliza.polizaType) {
            case 'Flota':
              return (
                <>
                  <label className="mt-3">
                    PDF póliza<span className="requiredFormField">*</span>{' '}
                  </label>
                  <input
                    className="form-control"
                    name="polizaPdf"
                    type="file"
                    accept=".pdf, image/jpeg, image/png, image/gif"
                    multiple={false}
                    required
                  />
                  <br />
                  <h5>Añadir vehículos a la flota</h5>
                  {newPoliza.float.map((flota, index) => (
                    <div key={index}>
                      <hr />
                      <h5>Vehículo {index + 1}</h5>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                        }}
                      >
                        <label className="mt-3">
                          Dominio <span className="requiredFormField">*</span>{' '}
                        </label>
                        <img
                          src="/images/delete.png"
                          style={{ width: '15px', height: '15px' }}
                          alt="Eliminar"
                          onClick={() => handleRemoveFloat(index)}
                        />
                      </div>

                      <input
                        className="form-control"
                        name={`float.${index}.domain`}
                        type="text"
                        placeholder="Dominio"
                        value={flota.domain}
                        onChange={(e) => handleOnChangePoliza(e, index)}
                        required
                      />
                      <label className="mt-3">
                        Pdf Tarjeta circulacion{' '}
                        <span className="requiredFormField">*</span>
                      </label>
                      <input
                        className="form-control"
                        name={`float${index}.tarjetaCirculacion`}
                        type="file"
                        accept=".pdf, image/jpeg, image/png, image/gif"
                        multiple={false}
                        required
                      />
                      <br />
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-info mt-1 mb-2"
                    onClick={handleAddFloat}
                  >
                    +
                  </button>
                </>
              );
            case 'Automotor':
              return (
                <>
                  <label>
                    Dominio <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="domain"
                    placeholder="Ingresá acá el dominio"
                    onChange={(e) => handleOnChangePoliza(e)}
                    type="text"
                    required
                  />
                  <label className="mt-3">
                    PDF póliza<span className="requiredFormField">*</span>{' '}
                  </label>
                  <input
                    className="form-control"
                    name="polizaPdf"
                    type="file"
                    accept=".pdf, image/jpeg, image/png, image/gif"
                    multiple
                    required
                  />
                  <label className="mt-3">
                    PDF tarjeta de circulación
                    <span className="requiredFormField">*</span>{' '}
                  </label>
                  <input
                    className="form-control"
                    name="tarjetaCirculacionPdf"
                    type="file"
                    accept=".pdf, image/jpeg, image/png, image/gif"
                    multiple
                    required
                  />
                </>
              );
            case 'Motovehículo':
              return (
                <>
                  <label>
                    Dominio <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="domain"
                    placeholder="Ingresá acá el dominio"
                    onChange={(e) => handleOnChangePoliza(e)}
                    type="text"
                    required
                  />
                  <label className="mt-3">
                    PDF póliza<span className="requiredFormField">*</span>{' '}
                  </label>
                  <input
                    className="form-control"
                    name="polizaPdf"
                    type="file"
                    accept=".pdf, image/jpeg, image/png, image/gif"
                    multiple
                    required
                  />
                  <label className="mt-3">
                    PDF tarjeta de circulación
                    <span className="requiredFormField">*</span>{' '}
                  </label>
                  <input
                    className="form-control"
                    name="tarjetaCirculacionPdf"
                    type="file"
                    accept=".pdf, image/jpeg, image/png, image/gif"
                    multiple
                    required
                  />
                </>
              );
            default:
              return (
                <>
                  <label className="mt-3">
                    PDF(s) póliza <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="polizaPdf"
                    type="file"
                    accept=".pdf, image/jpeg, image/png, image/gif"
                    multiple
                    required
                  />
                </>
              );
          }
        })()}
        <div className={styles.submitButtonWrapper}>
          <button
            type="submit"
            className="btn btn-success mt-4"
            disabled={loading}
          >
            {loading ? (
              <div className="spinner-border text-light" role="status"></div>
            ) : (
              'Cargar Póliza'
            )}
          </button>
        </div>
      </form>
    </>
  );
};
