import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { formatearFecha } from '../../../utils/date';
import { roles } from '../../../constants';
import { Chip } from '../../../components/Chip/Chip';
import { useAppSelector } from '../../../redux/hooks';
import { usersSelector } from '../../../redux/users';
import { CardChips } from '../../../components/Chips/CardChips';

const SiniestroCard = (props) => {
  const user = useAppSelector(usersSelector.userData);

  const fechaCargado = new Date(props.siniestro.createdAt);
  const fechaOcurrido = new Date(props.siniestro.siniestro.date);

  const fechaCargadaFormateada = formatearFecha(fechaCargado);
  const fechaOcurridoFormateada = formatearFecha(fechaOcurrido);
  return (
    props.siniestro.polizaId &&
    props.siniestro.userId && (
      <div className="card text-center mb-3 mx-4 w-90">
        <div className="card-body">
          <CardChips siniestro={props.siniestro} />
          <h5 className="card-title text-start">
            {props.siniestro.userId.socialReason},{' '}
            {props.siniestro.siniestro.tipoSiniestro}
          </h5>
          {props.siniestro.polizaId.domain &&
            props.siniestro.polizaId.domain !== '' ? (
            <p className="card-text text-start">
              <b>Dominio:</b> {props.siniestro.polizaId.domain}
            </p>
          ) : (
            <p className="card-text text-start">
              <b>Póliza:</b> {props.siniestro.polizaId.polizaNumber}
            </p>
          )}
          {user.role === roles.supreme &&
            !!!props.siniestro.denunciaFiles.length && (
              <Link
                to={`/siniestros/editar/${props.siniestro._id}`}
                className="btn btn-primary mb-1"
              >
                Cargar denuncia
              </Link>
            )}
          <Link
            to={`/siniestros/${props.siniestro._id}`}
            className="btn btn-warning"
          >
            Ver siniestro
          </Link>
        </div>
        <div className="card-footer text-body-secondary rowCardList row row-cols-sm-1 row-cols-md-1 row-cols-lg-2 ">
          <p className="mb-md-3 mb-lg-0 text-lg-start text-md-center">
            Cargado el {fechaCargadaFormateada}
          </p>
          <p className="mb-0 text-lg-end text-md-center">
            Suceso ocurrido el {fechaOcurridoFormateada}
          </p>
        </div>
      </div>
    )
  );
};

export default SiniestroCard;
