import { createAsyncThunk } from "@reduxjs/toolkit";
import { statsStoreKey } from "./stats.const";
import axios from "axios";
import { errorSideAlert } from "../alerts";


const statsPath = `${process.env.REACT_APP_BACKEND_URL}/stats`

export const getUsersStats = createAsyncThunk(
  `${statsStoreKey}/getUsersStats`,
  async (_, thunkApi) => {
    try {
      const response = await axios.get(statsPath + '/users');

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const getPolizasStats = createAsyncThunk(
  `${statsStoreKey}/getPolizasStats`,
  async (_, thunkApi) => {
    try {
      const response = await axios.get(statsPath + '/polizas');

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const getHomeStats = createAsyncThunk(
  `${statsStoreKey}/getHomeStats`,
  async (query, thunkApi) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/data${query || ''}`);

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message || 'Error obteniendo estadísticas gráficas.'));
      return null;
    }
  }
);