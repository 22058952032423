import React, { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { useSearchParams, useLocation, Link } from 'react-router-dom';
import Paginator from '../../components/Paginator/Paginator';
import { pagination, roles } from '../../constants';
import queryString from 'query-string';
import StudyCard from './StudyCard/StudyCard';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { usersSelector } from '../../redux/users';
import { downloadStudiesReport, getStudies, studiesSelectors, updateStudyStatus } from '../../redux/studies';
import './Studies.scss';

const { DEFAULT_PAGE, DEFAULT_LIMIT } = pagination;

const Studies = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);

  const loadingStudies = useAppSelector(studiesSelectors.isLoading);
  const studies = useAppSelector(studiesSelectors.studiesData);

  const [_, setParams] = useSearchParams();
  const location = useLocation();
  const [search, setSearch] = useState({
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    orderedBy: '',
    medic: '',
    clinic: '',
    createdAt: '',
  });
  const [onlyAnulled, setOnlyAnulled] = useState(false);
  const [onlyInvoiced, setOnlyInvoiced] = useState(false);
  const [onlyFulfilled, setOnlyFulfilled] = useState(false);

  const handleGetStudies = async (query) => {
    dispatch(getStudies(query));
  };

  const handleOnReset = () => {
    setSearch({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
      orderedBy: '',
      medic: '',
      clinic: '',
    });
    setParams({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
    });
    handleGetStudies(`?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setParams(search);
    const queryStringSearch = queryString.stringify(search);
    handleGetStudies('?' + queryStringSearch);
  };
  const handleOnChange = (e) => {
    if (e.target.name === 'anulled') {
      setOnlyAnulled((prev) => !prev);
      return setSearch({
        ...search,
        anulled: !onlyAnulled,
      });
    }
    if (e.target.name === 'invoiced') {
      setOnlyInvoiced((prev) => !prev);
      return setSearch({
        ...search,
        invoiced: !onlyInvoiced,
      });
    }
    if (e.target.name === 'fulfilled') {
      setOnlyFulfilled((prev) => !prev);
      return setSearch({
        ...search,
        fulfilled: !onlyFulfilled,
      });
    }
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    if (!queryObject.page || !queryObject.limit) {
      setParams({
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      });
    } else {
      setParams({
        ...queryObject,
        page: Number(queryObject.page),
      });
      setSearch({
        ...search,
        page: Number(queryObject.page),
      });
    }
    handleGetStudies(queryString);
  }, [location.search]);
  const onPrevPage = (page) => {
    setSearch({ ...search, page: page || (search.page -= 1) });
    setParams({ ...search, page: page || search.page });
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handleGetStudies(queryString);
  };
  const onNextPage = async (page) => {
    setSearch({ ...search, page: page || (search.page += 1) });
    setParams({ ...search, page: page || search.page });
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handleGetStudies(queryString);
  };

  const handleDownloadStudiesReport = async ({ from, to, orderedBy }) => {
    dispatch(downloadStudiesReport({ from, to, orderedBy }));
  };

  const updateStatus = async (status, id) => {
    const res = await dispatch(updateStudyStatus({ studyId: id, status })).unwrap();
    if (!res) return;

    handleGetStudies(location.search);
  };

  const showReportModal = () => {
    withReactContent(Swal).fire({
      title: 'Reporte de estudios médicos',
      html: (
        <div style={{ overflow: 'hidden', height: '100%', paddingTop: '10px' }}>
          <label>
            Solicitado por
          </label>
          <input
            style={{ maxWidth: '250px', margin: 'auto' }}
            className="form-control"
            type="text"
            name="orderedBy"
            id="report.orderedBy"
          />
          <label>
            Desde <span className="requiredFormField">*</span>
          </label>
          <input
            style={{ maxWidth: '250px', margin: 'auto' }}
            className="form-control"
            type="date"
            name="from"
            id="report.from"
          />
          <label>
            Hasta <span className="requiredFormField">*</span>
          </label>
          <input
            style={{ maxWidth: '250px', margin: 'auto' }}
            className="form-control"
            type="date"
            name="to"
            id="report.to"
          />
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Descargar',
      confirmButtonColor: 'green',
      preConfirm: () =>
        handleDownloadStudiesReport({
          orderedBy: document.getElementById('report.orderedBy').value,
          from: document.getElementById('report.from').value,
          to: document.getElementById('report.to').value,
        }),
    });
  };

  return (
    <>
      <h1>Medicina Laboral</h1>
      <div className="contentContainer card">
        <div className="card-header w-100 text-center">
          <form onSubmit={(e) => handleOnSubmit(e)}>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1 justify-content-center">
              <div className="form-group mb-2">
                <label>Solicitante</label>
                <input
                  className="form-control"
                  onChange={(e) => handleOnChange(e)}
                  name="orderedBy"
                  value={search.orderedBy}
                />
              </div>
              <div className="form-group mb-2">
                <label>Médico</label>
                <input
                  disabled={
                    search.clinic !== ''
                      ? true
                      : false
                  }
                  className="form-control"
                  onChange={(e) => handleOnChange(e)}
                  name="medic"
                  value={search.medic}
                />
              </div>
              <div className="form-group mb-2">
                <label>Prestador</label>
                <input
                  disabled={
                    search.medic !== ''
                      ? true
                      : false
                  }
                  className="form-control"
                  onChange={(e) => handleOnChange(e)}
                  name="clinic"
                  value={search.clinic}
                />
              </div>
              <div className="form-group mb-2">
                <label>Fecha de carga</label>
                <input
                  className="form-control"
                  type='date'
                  onChange={(e) => handleOnChange(e)}
                  name="createdAt"
                  value={search.createdAt}
                />
              </div>
              <div
                className="form-check form-switch switcherWrapper mb-2"
                style={{ marginRight: '15px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="anulled"
                  role="switch"
                  checked={onlyAnulled}
                  onChange={(e) => handleOnChange(e)}
                  id="flexSwitchCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  Sólo anulados
                </label>
              </div>
              <div
                className="form-check form-switch switcherWrapper mb-2"
                style={{ marginLeft: '15px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="invoiced"
                  role="switch"
                  checked={onlyInvoiced}
                  onChange={(e) => handleOnChange(e)}
                  id="flexSwitchCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  Sólo facturados
                </label>
              </div>
              <div
                className="form-check form-switch switcherWrapper mb-2"
                style={{ marginLeft: '15px' }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="fulfilled"
                  role="switch"
                  checked={onlyFulfilled}
                  onChange={(e) => handleOnChange(e)}
                  id="flexSwitchCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                  Sólo realizados
                </label>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center">
              <button type="reset" onClick={handleOnReset} className="btn btn-danger">
                LIMPIAR
              </button>
              <button type="submit" className="btn btn-success">
                FILTRAR
              </button>
            </div>
          </form>
        </div>
        <div className="doubleButtonWrapper">
          <Link to="/medicina-laboral/cargar" className="fw-semibold btn btn-primary my-2">
            CARGAR SOLICITUD DE ESTUDIOS
          </Link>
          <button
            onClick={showReportModal}
            style={{ width: '100%' }}
            className="fw-semibold btn btn-danger my-2"
          >
            REPORTE ESTUDIOS
          </button>
        </div>
        {loadingStudies ? (
          <Spinner />
        ) : (
          studies && (
            <>
              <p className="mb-1">Resultados: {studies.totalDocs}</p>
              {studies.docs.length ? (
                studies.docs.map((study) => (
                  <StudyCard key={study._id} study={study} updateStatus={updateStatus} />
                ))
              ) : user.role === roles.client ? (
                <h5 className="mt-4 mb-4">No posees ninguna solicitud de estudios médicos</h5>
              ) : (
                <h5 className="mt-4 mb-4">No se han encontrado resultados</h5>
              )}
            </>
          )
        )}

        {!loadingStudies && studies && (
          <Paginator
            pages={{
              hasPrevPage: studies.hasPrevPage,
              page: studies.page,
              totalPages: studies.totalPages,
              hasNextPage: studies.hasNextPage,
              onPrevPage: onPrevPage,
              onNextPage: onNextPage,
            }}
          />
        )}
      </div>
    </>
  );
};

export default Studies;
