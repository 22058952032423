import { React, useState, useEffect, useContext } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import Spinner from '../../../components/Spinner/Spinner';
import queryString from 'query-string';
import { useAppDispatch } from '../../../redux/hooks';
import { getHomeStats } from '../../../redux/stats/stats.thunks';

const Charts = () => {
  const dispatch = useAppDispatch();

  const [userData, setUserData] = useState(false);
  const [siniestroData, setSiniestroData] = useState(false);
  const [polizaData, setPolizaData] = useState(false);
  const [studiesData, setStudiesData] = useState(false);
  const [queryDataSearch, setQueryDataSearch] = useState(false);

  const getData = async (query) => {
    const res = await dispatch(getHomeStats(query)).unwrap();
    if (!res) return;

    setUserData({
      labels: res.users.map((data) => {
        let year = data.date.slice(2, 4);
        let dayAndMonth = data.date
          .split('-')
          .slice(1, 3)
          .reverse()
          .join('-');
        return `${dayAndMonth}-${year}`;
      }),
      datasets: [
        {
          label: 'Usuarios',
          data: res.users.map((data) => data.count),
          backgroundColor: ['skyblue'],
        },
      ],
    });
    setSiniestroData({
      labels: res.siniestros.map((data) => {
        let year = data.date.slice(2, 4);
        let dayAndMonth = data.date
          .split('-')
          .slice(1, 3)
          .reverse()
          .join('-');
        return `${dayAndMonth}-${year}`;
      }),
      datasets: [
        {
          label: 'Siniestros Nuevos',
          data: res.siniestros.map((data) => data.count),
          backgroundColor: ['orange'],
        },
      ],
    });
    setPolizaData({
      labels: res.polizas.map((data) => {
        let year = data.date.slice(2, 4);
        let dayAndMonth = data.date
          .split('-')
          .slice(1, 3)
          .reverse()
          .join('-');
        return `${dayAndMonth}-${year}`;
      }),
      datasets: [
        {
          label: 'Polizas Nuevas',
          data: res.polizas.map((data) => data.count),
          backgroundColor: ['green'],
        },
      ],
    });
    setStudiesData({
      labels: res.polizas.map((data) => {
        let year = data.date.slice(2, 4);
        let dayAndMonth = data.date
          .split('-')
          .slice(1, 3)
          .reverse()
          .join('-');
        return `${dayAndMonth}-${year}`;
      }),
      datasets: [
        {
          label: 'Solicitudes de estudios',
          data: res.studies.map((data) => data.count),
          backgroundColor: ['red'],
        },
      ],
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const options = {
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const queryStringSearch = queryString.stringify(queryDataSearch);
    getData('?' + queryStringSearch);
  };
  const handleOnChange = (e) => {
    setQueryDataSearch({
      ...queryDataSearch,
      [e.target.name]: e.target.value,
    });
  };
  const handleOnReset = () => {
    setQueryDataSearch(false);
    getData();
  };
  return (
    <>
      <hr />
      <h2 className="text-center mb-1">Gráficos </h2>
      <form className="mb-4" onSubmit={(e) => handleOnSubmit(e)}>
        <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1 justify-content-center">
          <div className="form-group mb-2">
            <label>Seleccione visualización por mes</label>
            <select
              className="form-select"
              name="monthsAgo"
              onChange={(e) => handleOnChange(e)}
            >
              <option value="1">30 días atrás</option>
              <option value="2">60 días atrás</option>
              <option value="3">90 días atrás</option>
              <option value="4">120 días atrás</option>
              <option value="5">150 días atrás</option>
              <option value="6">180 días atrás</option>
            </select>
          </div>
          <div className="form-group mb-2">
            <label>Seleccione visualización por año</label>
            <select
              className="form-select"
              name="yearsAgo"
              onChange={(e) => handleOnChange(e)}
            >
              <option value="" hidden>
                ¿Cuántos años atrás quieres ver?
              </option>
              <option value="1">1 año atrás</option>
              <option value="2">2 años atrás</option>
              <option value="3">3 años atrás</option>
              <option value="4">4 años atrás</option>
              <option value="5">5 años atrás</option>
              <option value="6">6 años atrás</option>
            </select>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center">
          <button
            type="reset"
            onClick={() => handleOnReset()}
            className="btn btn-danger"
          >
            LIMPIAR
          </button>
          <button type="submit" className="btn btn-success">
            BUSCAR
          </button>
        </div>
      </form>
      {userData && siniestroData && polizaData ? (
        <>
          <div className="chartContainer">
            <h5 className="card-title text-center">Usuarios</h5>
            <Bar data={userData} options={options} />
          </div>
          <div className="chartContainer">
            <h5 className="card-title text-center">Polizas</h5>
            <Bar data={polizaData} options={options} />
          </div>
          <div className="chartContainer">
            <h5 className="card-title text-center">Siniestros</h5>
            <Bar data={siniestroData} options={options} />
          </div>
          <div className="chartContainer">
            <h5 className="card-title text-center">Estudios</h5>
            <Bar data={studiesData} options={options} />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Charts;
