import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import { formatDateToYYYYMMDD, formatearFecha } from '../../../utils/date';
import { roles } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { usersSelector } from '../../../redux/users';
import { getStudyDetail, updateStudyStatus } from '../../../redux/studies';
import { DetailChips } from '../../../components/Chips/DetailChips';

const StudyDetail = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);

  const { studyId } = useParams();
  const [studyDetail, setStudyDetail] = useState(false);

  const getStudy = async () => {
    const res = await dispatch(getStudyDetail(studyId)).unwrap();
    if (!res) return;

    setStudyDetail(res);
  };

  useEffect(() => {
    getStudy();
  }, [studyId]);

  const handleAnulleStudy = async () => {
    const res = await dispatch(updateStudyStatus({ studyId, status: 'anulled', value: !studyDetail.anulled })).unwrap();
    if (!res) return;
    setStudyDetail(res);
  }

  if (!studyDetail) return <Spinner />

  return (
    <>
      <h1>Solicitud de estudios</h1>
      {studyDetail ? (
        <>
          <div className="card text-center mb-3 mx-4 pb-4" style={{ width: "100%" }}>
            <div className="card-body">
              <DetailChips study={studyDetail} />
              <h5 className="mb-4">
                <b>Solicitado por:</b> {studyDetail.orderedBy}
              </h5>
              {/* <p className="mb-0"><b>Póliza de tipo:</b> {studyDetail.polizaType}</p> */}
              {studyDetail.medic && (
                <p className="mb-0">
                  <b>Medico:</b> {studyDetail.medic}
                </p>
              )}
              {studyDetail.clinic && (
                <p className="mb-0">
                  <b>Prestador:</b> {studyDetail.clinic}
                </p>
              )}
              <p className="mb-0">
                <b>Estudio/Servicio:</b> {studyDetail.type}
              </p>
              <p className="mb-0">
                <b>Provincia:</b> {studyDetail.state}
              </p>
              <p className="mb-0 mt-4">
                <b>Cargado el día:</b>{' '}
                {formatearFecha(new Date(studyDetail.createdAt))}
              </p>
              <h5 className="card-title mt-4">
                Pacientes del estudio médico/servicio
              </h5>
              <div
                style={{
                  display: 'flex',
                  padding: '10px 20px',
                  gap: '20px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {studyDetail.patients.length ? (
                  studyDetail.patients.map((patient, index) => (
                    <div key={index} className="card" style={{ padding: '0.5rem' }}>
                      <label className="mt-0" style={{ width: "100%" }}>
                        Nombre y apellido
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="socialReason"
                        value={patient.socialReason || ''}
                        disabled={true}
                      />
                      <label className="mt-1" style={{ width: "100%" }}>
                        DNI
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="dni"
                        value={patient.dni || ''}
                        disabled={true}
                      />
                      <label className="mt-1" style={{ width: "100%" }}>
                        Teléfono
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="phone"
                        value={patient.phone || ''}
                        disabled={true}
                      />
                      <label className="mt-1" style={{ width: "100%" }}>
                        Fecha de turno
                      </label>
                      <input
                        type="date"
                        className="form-control mb-2"
                        name="date"
                        value={formatDateToYYYYMMDD(patient.date)}
                        disabled={true}
                      />
                    </div>
                  ))
                ) : (
                  <p>
                    Parecen no haber pacientes/receptores en esta solicitud de
                    medicina laboral{' '}
                  </p>
                )}
              </div>
              <div style={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
                <p style={{ maxWidth: '500px' }}><b>Observaciones: </b>{studyDetail.details}</p>
              </div>
            </div>

          </div>
          {user.role === roles.supreme && (
            <>
              {!studyDetail.anulled ? (
                <button
                  className="btn btn-danger  w-90"
                  onClick={handleAnulleStudy}
                >
                  ANULAR SOLICITUD DE ESTUDIOS
                </button>
              ) : (
                <button
                  className="btn btn-success w-90"
                  onClick={handleAnulleStudy}
                >
                  DES-ANULAR SOLICITUD DE ESTUDIOS
                </button>
              )}
              <Link
                to={`/medicina-laboral/editar/${studyId}`}
                className="mt-2 w-90 btn btn-primary"
              >
                EDITAR
              </Link>
            </>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default StudyDetail;
