import React, { useEffect, useState } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import SiniestroCard from './SiniestroCard/SiniestroCard';
import Spinner from '../../components/Spinner/Spinner';
import Paginator from '../../components/Paginator/Paginator';
import {
  roles,
  pagination,
} from '../../constants';
import queryString from 'query-string';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { usersSelector } from '../../redux/users';
import './Siniestros.scss';
import { siniestrosSelectors, getSiniestros } from '../../redux/siniestros';
import { essentialsSelectors } from '../../redux/essentials';

const { DEFAULT_PAGE, DEFAULT_LIMIT } = pagination;

const Siniestros = () => {
  const dispatch = useAppDispatch();
  
  const user = useAppSelector(usersSelector.userData);
  const loadingSiniestros = useAppSelector(siniestrosSelectors.isLoading);
  const siniestros = useAppSelector(siniestrosSelectors.siniestrosData);
  const insuranceCompanies = useAppSelector(essentialsSelectors.insuranceCompanies);
  const insuranceTypes = useAppSelector(essentialsSelectors.insuranceTypes);

  const [_, setParams] = useSearchParams();
  const location = useLocation();
  const [onlyDenunciados, setOnlyDenunciados] = useState(false);
  const [search, setSearch] = useState({
    page: 1,
    limit: 20,
  });

  const handleGetSiniestros = async (query) => {
    dispatch(getSiniestros(query));
  };

  const handleOnReset = () => {
    setSearch({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
    });
    setParams({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
    });
    handleGetSiniestros(`?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setParams(search);
    const queryStringSearch = queryString.stringify(search);
    handleGetSiniestros('?' + queryStringSearch);
  };
  const handleOnChange = (e) => {
    if (e.target.name === 'reported') {
      setOnlyDenunciados((prev) => !prev);
      return setSearch({
        ...search,
        reported: !onlyDenunciados,
      });
    }
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    const insuranceCompanyFromParams = queryObject.insuranceCompany || 'all';
    setSearch({
      ...search,
      insuranceCompany: insuranceCompanyFromParams,
    });
    if (!queryObject.page || !queryObject.limit) {
      setParams({
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      });
    } else {
      setParams({
        ...queryObject,
        page: Number(queryObject.page),
      });
      setSearch({
        ...search,
        page: Number(queryObject.page),
      });
    }
    handleGetSiniestros(queryString);
  }, [location.search]);
  const onPrevPage = (page) => {
    setSearch({ ...search, page: page || (search.page -= 1) });
    setParams({ ...search, page: page || search.page });
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handleGetSiniestros(queryString);
  };
  const onNextPage = async (page) => {
    setSearch({ ...search, page: page || (search.page += 1) });
    setParams({ ...search, page: page || search.page });
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handleGetSiniestros(queryString);
  };
  return (
    <>
      <h1>Siniestros</h1>
      <div className="contentContainer card">
        <div className="card-header w-100 text-center ">
          <form onSubmit={(e) => handleOnSubmit(e)}>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1 d-flex justify-content-center">
              <div className="form-group mb-2">
                <label>Aseguradora</label>
                <select
                  onChange={(e) => handleOnChange(e)}
                  name="insuranceCompany"
                  className="form-select"
                >
                  <option value="">Todas</option>
                  {insuranceCompanies.map((insuranceCompany, index) => {
                    return (
                      <option
                        key={`insuranceCompany${index}`}
                        value={insuranceCompany.value}
                      >
                        {insuranceCompany.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Categoría</label>
                <select
                  onChange={(e) => handleOnChange(e)}
                  name="polizaType"
                  className="form-select"
                >
                  <option value="">Todas</option>
                  {insuranceTypes.map((insuranceType, index) => {
                    return (
                      <option
                        key={`insuranceType${index}`}
                        value={insuranceType.value}
                      >
                        {insuranceType.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Tipo de documento </label>
                <select
                  onChange={(e) => handleOnChange(e)}
                  name="documentType"
                  className="form-select"
                  title="Selecciona el tipo de documento"
                  required={search.documentNumber}
                >
                  <option value="">Tipo de documento</option>
                  <option value="CUIT">CUIT</option>
                  <option value="DNI">DNI</option>
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Número de documento</label>
                <input
                  placeholder="Número de documento"
                  className="form-control"
                  name="documentNumber"
                  onChange={(e) => handleOnChange(e)}
                  type="number"
                  required={search.documentType}
                />
              </div>
              {user.role === roles.supreme && (
                <div className="form-check form-switch switcherWrapper mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="reported"
                    role="switch"
                    checked={onlyDenunciados}
                    onChange={(e) => handleOnChange(e)}
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Sólo sin denuncias cargadas
                  </label>
                </div>
              )}
            </div>
            <div className="d-flex gap-2 justify-content-center">
              <button
                type="reset"
                onClick={handleOnReset}
                className="btn btn-danger"
              >
                LIMPIAR
              </button>
              <button type="submit" className="btn btn-success">
                FILTRAR
              </button>
            </div>
          </form>
        </div>
        <div className="d-flex w-50 justify-content-center">
          <Link
            to="/siniestros/reportar"
            className="fw-semibold btn btn-warning my-2"
          >
            DENUNCIAR SINIESTRO
          </Link>
        </div>
        {loadingSiniestros ? (
          <Spinner />
        ) : (
          siniestros && (
            <>
              <p className="mb-1">Resultados: {siniestros.totalDocs}</p>
              {siniestros.docs.length > 0 ? (
                siniestros.docs.map((siniestro) => (
                  <SiniestroCard key={siniestro._id} siniestro={siniestro} />
                ))
              ) : user.role === roles.client ? (
                <h5 className="mt-4 mb-4">
                  No posees ningun siniestro cargado
                </h5>
              ) : (
                <h5 className="mt-4 mb-4">No se han encontrado resultados</h5>
              )}
            </>
          )
        )}
        {!loadingSiniestros && siniestros && (
          <Paginator
            pages={{
              hasPrevPage: siniestros.hasPrevPage,
              page: siniestros.page,
              hasNextPage: siniestros.hasNextPage,
              totalPages: siniestros.totalPages,
              onPrevPage: onPrevPage,
              onNextPage: onNextPage,
            }}
          />
        )}
      </div>
    </>
  );
};

export default Siniestros;
