import React, { useContext, useEffect, useState } from 'react';
import './NewUser.scss';
import axios from 'axios';
import { roles, states } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import RadioInputSelector from '../../../components/RadioInputSelector/RadioInputSelector';
import { warningSideAlert } from '../../../redux/alerts';
import { useAppDispatch } from '../../../redux/hooks';
import { createNewUser, getUsersByRole } from '../../../redux/users';

const NewUser = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [user, setUser] = useState({
    socialReason: '',
    email: '',
    document: {
      documentType: '',
      documentNumber: '',
    },
    assignedTo: '',
    phoneNumber: '',
    role: '',
  });
  const [address, setAddress] = useState({
    state: '',
    location: '',
    street: '',
    number: '',
    floor: '',
    apartment: '',
  });

  const [admins, setAdmins] = useState(false);
  const [enterpriseUsers, setEnterpriseUsers] = useState(false);
  const [assignToEnterpriseUser, setAssignToEnterpriseUser] = useState(false);

  const getAdminsList = async () => {
    const res = await dispatch(getUsersByRole([roles.admin, roles.supreme])).unwrap();
    if (!res) return;
    setAdmins(res);
  };

  const getEnterpriseUsers = async () => {
    const res = await dispatch(getUsersByRole([roles.clientEnterprise])).unwrap();
    if (!res) return;
    setEnterpriseUsers(res);
  };

  useEffect(() => {
    getAdminsList();
    getEnterpriseUsers();
  }, []);

  const handleOnChange = (e) => {
    if (
      e.target.name === 'documentType' ||
      e.target.name === 'documentNumber'
    ) {
      return setUser({
        ...user,
        document: {
          ...user.document,
          [e.target.name]: e.target.value,
        },
      });
    }
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (
      user.document.documentType !== 'DNI' &&
      user.document.documentType !== 'CUIT'
    ) {
      dispatch(warningSideAlert('Selecciona un tipo de documento'));
    } else {
      const created = await dispatch(createNewUser({ ...user, address })).unwrap();

      if (created) navigate(`/usuarios/${created._id}`);
    }
  };

  const handleOnChangeAddress = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <h1>Usuarios</h1>
      <div className="contentContainer">
        <h2 className="text-center mt-3 mb-1">Registrar </h2>
        <form onSubmit={handleOnSubmit} className="newUserForm">
          <label>Razón Social:</label>
          <input
            className="form-control"
            name="socialReason"
            onChange={handleOnChange}
            type="text"
          />
          <label>Email</label>
          <input
            className="form-control"
            name="email"
            onChange={handleOnChange}
            type="email"
          />
          <label>Tipo de documento</label>
          <select
            onChange={handleOnChange}
            name="documentType"
            className="form-select"
          >
            <option>Tipo de documento</option>
            <option value="DNI">DNI</option>
            <option value="CUIT">CUIT</option>
          </select>
          <label>Nº de Documento</label>
          <input
            className="form-control"
            onChange={handleOnChange}
            name="documentNumber"
            value={user.document.documentNumber}
          />
          <label>Nº de Teléfono</label>
          <input
            style={{ width: '100%' }}
            onChange={handleOnChange}
            name="phoneNumber"
            className="form-control"
            type="text"
          />
          <label htmlFor="state">Provincia</label>
          <select
            onChange={handleOnChangeAddress}
            name="state"
            className="form-select"
          >
            <option value="">Selecciona una provincia</option>
            {states.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
          <label>Localidad</label>
          <input
            onChange={handleOnChangeAddress}
            name="location"
            className="form-control"
            type="text"
          />
          <label>Calle</label>
          <input
            onChange={handleOnChangeAddress}
            name="street"
            className="form-control"
            type="text"
          />
          <label>Altura / Número</label>
          <input
            onChange={handleOnChangeAddress}
            name="number"
            className="form-control"
            type="number"
          />
          <div
            style={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>Piso</label>
              <input
                style={{ width: '100%' }}
                onChange={handleOnChangeAddress}
                name="floor"
                className="form-control"
                type="number"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>Nº de Departamento</label>
              <input
                style={{ width: '100%' }}
                onChange={handleOnChangeAddress}
                name="apartment"
                className="form-control"
                type="text"
              />
            </div>
          </div>
          <label>Rol del usuario a crear</label>
          <select onChange={handleOnChange} name="role" className="form-select">
            <option value="" hidden>
              Rol del usuario
            </option>
            <option value={roles.client}>Cliente</option>
            <option value={roles.clientEnterprise}>Grupo empresario</option>
            <option value={roles.admin}>Vendedor</option>
          </select>
          {user.role === roles.client ? (
            <>
              <label>A qué vendedor le asignarás este cliente?</label>
              <select
                onChange={handleOnChange}
                name="assignedTo"
                className="form-select"
              >
                <option value="" hidden>
                  Selecciona al vendedor
                </option>
                {admins
                  ? admins.map((seller) => (
                    <option key={seller._id} value={seller._id}>
                      {seller.socialReason}
                    </option>
                  ))
                  : false}
              </select>
              <RadioInputSelector
                title="¿Este usuario corresponde a un grupo empresario?"
                setState={setAssignToEnterpriseUser}
                state={assignToEnterpriseUser}
              />
              {assignToEnterpriseUser ? (
                <>
                  <label>
                    A qué grupo empresario le asignarás este usuario?
                  </label>
                  <select
                    onChange={handleOnChange}
                    name="assignedToEnterprise"
                    className="form-select"
                  >
                    <option value="" hidden>
                      Selecciona el grupo empresario
                    </option>
                    {enterpriseUsers
                      ? enterpriseUsers.map((enterpriseUser) => (
                        <option
                          key={enterpriseUser._id}
                          value={enterpriseUser._id}
                        >
                          {enterpriseUser.socialReason}{' '}
                          <b>{enterpriseUser.document.documentType}: </b>
                          {enterpriseUser.document.documentNumber}
                        </option>
                      ))
                      : false}
                  </select>
                </>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}
          <p className="infoMessage mt-2">
            La contraseña por defecto será el número de documento (Sea DNI o
            CUIT)
          </p>
          <button type="submit" className="btn btn-success mt-3">
            Registrar Usuario
          </button>
        </form>
      </div>
    </>
  );
};

export default NewUser;
