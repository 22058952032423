import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from '../../../components/Spinner/Spinner';
import UserCard from './UserCard';
import Paginator from '../../../components/Paginator/Paginator';
import { useLocation, useSearchParams } from 'react-router-dom';
import { pagination, rolenames, roles } from '../../../constants';
import queryString from 'query-string';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { downloadUsersReport, getUsers, usersSelector } from '../../../redux/users';

const { DEFAULT_PAGE, DEFAULT_LIMIT } = pagination;

const UsersList = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);

  const [_, setParams] = useSearchParams();
  const location = useLocation();
  const [users, setUsers] = useState(false);
  const [search, setSearch] = useState({
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    documentType: '',
    documentNumber: '',
    socialReason: '',
  });

  const handlerGetUsers = async (query) => {
    const res = await dispatch(getUsers(query)).unwrap();
    if (!res) return;

    setUsers(res);
  };

  const handleOnReset = (e) => {
    setUsers(false);
    setSearch({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
      documentType: '',
      documentNumber: '',
      socialReason: '',
    });
    setParams({
      page: DEFAULT_PAGE,
      limit: DEFAULT_LIMIT,
    });
    handlerGetUsers(`?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setUsers(false);
    setParams(search);
    const queryStringSearch = queryString.stringify(search);
    handlerGetUsers('?' + queryStringSearch);
  };

  const handleOnChange = (e) => {
    if (
      e.target.name === 'documentType' ||
      e.target.name === 'documentNumber'
    ) {
      if (
        e.target.name === 'documentType' &&
        e.target.value !== 'DNI' &&
        e.target.value !== 'CUIT'
      )
        return;
    }
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    if (!queryObject.page || !queryObject.limit) {
      setParams({
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
      });
    } else {
      setParams({
        ...queryObject,
        page: Number(queryObject.page),
      });
      setSearch({
        ...search,
        page: Number(queryObject.page),
      });
    }
    handlerGetUsers(queryString);
  }, []);

  const onPrevPage = (page) => {
    setSearch({ ...search, page: page || (search.page -= 1) });
    setParams({ ...search, page: page || search.page });
    setUsers(false);
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handlerGetUsers(queryString);
  };

  const onNextPage = async (page) => {
    setSearch({ ...search, page: page || (search.page += 1) });
    setParams({ ...search, page: page || search.page });
    setUsers(false);
    let queryString = location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryObject = Object.fromEntries(searchParams.entries());
    queryObject.page = search.page;
    queryString = '?' + new URLSearchParams(queryObject).toString();
    handlerGetUsers(queryString);
  };

  const handleDownloadReport = async () => {
    dispatch(downloadUsersReport());
  };

  return (
    <>
      <h1 className="text-center">
        {user.role === roles.clientEnterprise ? 'Empresas' : 'Usuarios'}
      </h1>
      <div className="contentContainer card">
        <div className="card-header w-100 text-center mb-1">
          <form onSubmit={(e) => handleOnSubmit(e)}>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-1 d-flex justify-content-center">
              <div className="form-group mb-2">
                <label>Tipo de documento</label>
                <select
                  disabled={search.socialReason !== '' ? true : false}
                  onChange={(e) => handleOnChange(e)}
                  name="documentType"
                  className="form-select"
                >
                  <option>Tipo de documento</option>
                  <option value="DNI">DNI</option>
                  <option value="CUIT">CUIT</option>
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Nº de Documento</label>
                <input
                  disabled={search.socialReason !== '' ? true : false}
                  className="form-control"
                  onChange={(e) => handleOnChange(e)}
                  name="documentNumber"
                  value={search.documentNumber}
                />
              </div>{' '}
              <div className="form-group mb-2">
                <label>Razón Social</label>
                <input
                  disabled={
                    search.documentType !== '' || search.documentNumber !== ''
                      ? true
                      : false
                  }
                  className="form-control"
                  onChange={(e) => handleOnChange(e)}
                  name="socialReason"
                  value={search.socialReason}
                />
              </div>
              {user.role === roles.supreme && <div className="form-group mb-2">
                <label>Tipo de cliente</label>
                <select
                  className="form-select"
                  onChange={(e) => handleOnChange(e)}
                  name="role"
                  value={search.role}
                >
                  <option value=''>Todos</option>
                  {Object.keys(roles).map((role, index) => {
                    return (
                      <option key={`role${index}`} value={role}>
                        {rolenames[role]}
                      </option>
                    );
                  })}
                </select>
              </div>}
            </div>
            <div className="d-flex gap-2 justify-content-center">
              <button
                type="reset"
                className="btn btn-danger"
                onClick={handleOnReset}
              >
                LIMPIAR
              </button>
              <button type="submit" className="btn btn-success">
                FILTRAR
              </button>
            </div>
          </form>
        </div>
        {user.role === roles.supreme && (
          <div className="doubleButtonWrapper">
            <button
              onClick={handleDownloadReport}
              style={{ width: '50%' }}
              className="fw-semibold btn btn-primary my-2"
            >
              EXPORTAR CLIENTES
            </button>
          </div>
        )}
        {users?.docs ? (
          <>
            <p className="mb-1">Resultados: {users.totalDocs}</p>
            {users.docs.map((user) => (
              <UserCard key={user._id} user={user} />
            ))}
          </>
        ) : (
          <Spinner />
        )}

        <Paginator
          pages={{
            hasPrevPage: users.hasPrevPage,
            page: users.page,
            totalPages: users.totalPages,
            hasNextPage: users.hasNextPage,
            onPrevPage: onPrevPage,
            onNextPage: onNextPage,
          }}
        />
      </div>
    </>
  );
};

export default UsersList;
